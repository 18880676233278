<script>
import env from '../env';
import GlobalVue from '../helper/Global.vue';

export default {
	extends: GlobalVue,
	props:{ratio:Number},
	data(){
		return {
			width: 0,
			height: 0,
			child: null,
		}
	},
	mounted(){
		this.init()
	},
	methods:{
		init(){
			global.ratio = this
			this.child = this.$children[0]
			this.width = $(this.$el).width()
			this.height = this.width/this.ratio
			if(!this.child) return;
			this.child.$el.onload = ()=>{
				var img = this.child.$el
				$(img).removeAttr('style')
				// console.log("tes", this.ratio, img.width/img.height)
				if(this.ratio > img.width/img.height){
					$(img).css({width:"100%"})
					$(img).css({"margin-top": ($(img).height()-this.height)/-2})
				}else{
					$(img).css({height:"100%"})
					$(img).css({"margin-left": ($(img).width()-this.width)/-2})
				}
			}
		}
	},
	watch:{
		ratio(){ this.init() }
	}
}
</script>

<style lang="scss">
.v-ratio{
	overflow: hidden;
	position: relative;
	width: 100%;
	img{
    max-width: unset;
    max-height: unset;
	}
}
</style>

<template>
  <div class="v-ratio" :style="'height:'+this.height+'px;'">
    <slot></slot>
  </div>
</template>
